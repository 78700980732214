import { DefaultState, Display, GroupCtaBehavior, ICon, TargetPage, WidgetIcon, WorkingDayType } from '@/constants/enum';
import { defaultSelected } from '@/pages/NewWidget/listIconDefault';
import { IParamsApi } from '@/types/apis/params';
import { IChannelState, ICustomize, ITrigger } from '@/types/components/widget';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const initGroupButton = {
  name: '',
  channelsSelected: defaultSelected as IParamsApi.IChannelButton[],
  customize: {
    closeHoverText: 'Close',
    groupColor: 'black',
    bottomSpacing: 3,
    sideSpacing: 3,
    buttonDirection: Display.Vertical,
    openAction: DefaultState.Click,
    groupIcon: {
      key: WidgetIcon.WidgetIconChatBase,
    },
    ctaText: 'Contact Us',
    widgetSize: 45,
    ctaTextColor: 'black',
    ctaBackground: 'white',
    ctaBehavior: GroupCtaBehavior.HideClick,
    buttonStyle: '50%',
    customCss: '',
    groupIconColor: 'white',
  },
  trigger: {
    active: true,
    displayAfterSecondValue: '0',
    displayAfterScrollValue: '0',
    startWorkingTime: '00:00',
    endWorkingTime: '23:59',
    workingDays: [],
    targetRules: [],
    timezone: '',
    isUseWorkingTime: WorkingDayType.AllDay,
    pageTargetType: TargetPage.AllPage,
    specificPages: '',
    excludePages: '',
  },
};

const initialState: {
  data: IChannelState;
  backupData: IChannelState;
  window?: ICon;
  error: { status: boolean; icon?: ICon[]; field?: string; msg?: string };
} = {
  error: {
    status: false,
  },
  window: undefined,
  data: initGroupButton,
  backupData: initGroupButton,
};

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    handleChannelsSelected: (state, action: PayloadAction<IParamsApi.IChannelButton[]>) => {
      state.data.channelsSelected = action.payload;
    },
    handleCustomize: (state, action: PayloadAction<ICustomize>) => {
      state.data.customize = action.payload;
    },

    handleTrigger: (state, action: PayloadAction<ITrigger>) => {
      state.data.trigger = action.payload;
    },
    handleData: (state, action: PayloadAction<IChannelState>) => {
      state.error = {
        status: false,
      };
      state.data = action.payload;
    },
    handleDataBackup: (state, action: PayloadAction<IChannelState>) => {
      state.backupData = action.payload;
    },
    handleWindow: (state, action: PayloadAction<ICon | undefined>) => {
      state.window = action.payload;
    },
    handleError: (state, action: PayloadAction<{ status: boolean; icon?: ICon[]; field?: string; msg?: string }>) => {
      state.error = action.payload;
    },
  },
});

export const channelsSelectedSelector = createSelector(
  (state: RootState) => state.widget,
  (state) => state.data.channelsSelected,
);
export const customizeSelected = createSelector(
  (state: RootState) => state.widget,
  (state) => state.data.customize,
);

export const triggerSelector = createSelector(
  (state: RootState) => state.widget,
  (state) => state.data.trigger,
);

export const dataSelector = createSelector(
  (state: RootState) => state.widget,
  (state) => state.data,
);
export const dataBackupSelector = createSelector(
  (state: RootState) => state.widget,
  (state) => state.backupData,
);

export const windowSelector = createSelector(
  (state: RootState) => state.widget,
  (state) => state.window,
);

export const errorSelector = createSelector(
  (state: RootState) => state.widget,
  (state) => state.error,
);

export default widgetSlice;
