import { config } from '@/config';
import authSlice from '@/redux/slice/auth.slice';
import store from '@/redux/store';
import { IParamsApi } from '@/types/apis/params';
import { IResponseApi } from '@/types/apis/response';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge/utilities';
const params = {
  shop: config.shop,
};

const convertParams = (input?: any) => {
  return {
    ...params,
    ...input,
  };
};

const configApp = {
  apiKey: process.env.REACT_APP_API_KEY || '',
  host: new URLSearchParams(window.location.search).get('host') || '',
  forceRedirect: process.env.REACT_APP_MODE === 'live',
};

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_END_POINT,
    prepareHeaders: async (headers) => {
      if (process.env.REACT_APP_ENV === 'production') {
        headers.set('Content-Security-Policy', `frame-ancestors https://${params.shop} https://admin.shopify.com`);
      }
      headers.set('authorization-url-params', config.urlParams);

      // Handle add Shopify token
      if (config.embedded === '1') {
        if (
          (!store.getState().auth.token || Date.now() > store.getState().auth.expiredTime) &&
          !store.getState().auth.isLoading
        ) {
          try {
            store.dispatch(authSlice.actions.handleLoading(true));
            const app = createApp(configApp);
            const sessionToken = await getSessionToken(app);
            store.dispatch(
              authSlice.actions.handleToken({
                token: sessionToken,
                expiredTime: Date.now() + 60 * 1000,
                isLoading: false,
              }),
            );
            headers.set('authorization', `Bearer ${sessionToken}`);
          } catch (err) {
            store.dispatch(authSlice.actions.handleLoading(false));
          }
        }
      }

      if (store.getState().auth.token) {
        headers.set('authorization', `Bearer ${store.getState().auth.token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    'button-group',
    'button-group-detail',
    'general-setting',
    'group-summary',
    'list-form-answer',
    'list-button-has-form',
  ],
  endpoints: (builder) => ({
    generalSettings: builder.query<IResponseApi.IGeneralSettings, void>({
      query: (input) => {
        return {
          url: 'shop/general-setting',
          method: 'GET',
          params: convertParams(input),
        };
      },
      providesTags: [{ type: 'general-setting' }],
    }),
    createGroupButton: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.ICreateGroupButton>({
      query: (input) => {
        return {
          url: 'button/group/create',
          method: 'POST',
          body: convertParams(input),
        };
      },
      invalidatesTags: ['button-group', 'general-setting'],
    }),

    listGroupButton: builder.query<IResponseApi.IListButtonGroup, IParamsApi.IGetListGroupButton>({
      query: (input) => {
        return {
          url: 'button/group/summary',
          method: 'GET',
          params: convertParams(input),
        };
      },
      providesTags: ['button-group'],
    }),

    groupButtonDetail: builder.query<IResponseApi.IButtonGroupDetail, IParamsApi.IGroupButtonDetail>({
      query: (input) => {
        return {
          url: 'button/group/detail',
          method: 'GET',
          params: convertParams(input),
        };
      },
      providesTags: ['button-group-detail'],
    }),

    getAllButtons: builder.query<IResponseApi.IListButtonGroupOption, void>({
      query: () => {
        return {
          url: 'button/form-answer/list-button',
          method: 'GET',
          params: convertParams(),
        };
      },
      providesTags: ['list-button-has-form'],
    }),

    updateGroupButton: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IUpdateGroupButton>({
      query: (input) => {
        return {
          url: 'button/group/update',
          method: 'PUT',
          body: convertParams(input),
        };
      },
      invalidatesTags: ['button-group', 'button-group-detail', 'general-setting'],
    }),

    removeGroupButton: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IRemoveGroupButton>({
      query: (input) => {
        return {
          url: 'button/group/remove',
          method: 'DELETE',
          params: convertParams(input),
        };
      },
      invalidatesTags: ['button-group', 'general-setting', 'group-summary', 'list-form-answer', 'list-button-has-form'],
    }),

    getDataSummary: builder.query<IResponseApi.IGetDashboardSummary, IParamsApi.ITime>({
      query: (input) => {
        return {
          url: 'analytics/dashboard-summary',
          method: 'GET',
          params: convertParams(input),
        };
      },
      providesTags: ['group-summary'],
    }),

    getPages: builder.query<IResponseApi.IGetPages, void>({
      query: (input) => {
        return {
          url: 'shop/storefront/pages',
          method: 'GET',
          params: convertParams(input),
        };
      },
    }),

    getEmbeddedAppStatus: builder.query<IResponseApi.ICheckEmbedApp, void>({
      query: () => {
        return {
          url: 'shop/embedded-app-status',
          method: 'GET',
          params: convertParams({
            forceCheck: true,
          }),
        };
      },
    }),

    getListsFormAnswer: builder.query<IResponseApi.IGetFormAnswer, IParamsApi.IGetListFormAnswer>({
      query: (input) => {
        return {
          url: 'button/form-answer/list',
          method: 'GET',
          params: convertParams(input),
        };
      },
      providesTags: ['list-form-answer'],
    }),

    deleteFormAnswer: builder.mutation<IResponseApi.ICommonResponse, { id: number }>({
      query: (input) => {
        return {
          url: 'button/form-answer/remove',
          method: 'DELETE',
          params: convertParams(input),
        };
      },
      invalidatesTags: ['list-form-answer'],
    }),

    getAppPricing: builder.query<IResponseApi.IGetAppPricing, void>({
      query: () => {
        return {
          url: 'app-pricing',
          method: 'GET',
        };
      },
    }),

    chargePrice: builder.query<IResponseApi.IChargePrice, IParamsApi.IChargePrice>({
      query: (input) => {
        return {
          url: 'shop/charge',
          method: 'GET',
          params: convertParams(input),
        };
      },
    }),

    sendGA4Event: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.ISendGA4Event>({
      query: (input) => {
        return {
          url: '/track-action/ga4',
          method: 'POST',
          body: convertParams(input),
        };
      },
    }),

    redirectUpdateShopScope: builder.query<IResponseApi.IRedirectUpdateShopScope, IParamsApi.IRedirectUpdateShopScope>({
      query: (input) => {
        return {
          url: '/shop/redirect-update-shop-scope',
          method: 'GET',
          params: convertParams(input),
        };
      },
    }),
  }),
});
