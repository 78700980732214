import { images } from '@/asset';
import { Ga4Event } from '@/constants/enum';

export const configRecommend = [
  {
    title: 'Estimate Delivery Date',
    description: 'Set clear delivery expectations to stop “Where’s my order?” inquiries before they happen.',
    buttonText: 'Learn more',
    logo: images.estimated_delivery,
    href: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
    hasBFS: true,
    ga4Event: Ga4Event.EstimatedClick,
  },
  {
    title: 'Order Tracking',
    hasBFS: true,
    description: 'Give customers real-time order updates, reducing the need for follow-ups.',
    buttonText: 'Learn more',
    logo: images.order_tracking_app,
    href: 'https://apps.shopify.com/omega-order-tracking?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
    ga4Event: Ga4Event.OderTrackingClick,
  },
  {
    title: 'Return & Exchanges',
    hasBFS: true,
    description: 'Allow customers to self-serve and streamline the return process, saving you time while keeping them happy.',
    buttonText: 'Learn more',
    logo: images.return_drive_app,
    href: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
    ga4Event: Ga4Event.ReturnClick,
  },
];
// export const configRecommend = [
//   {
//     title: 'S: Estimated Delivery Date ETA',
//     description: 'Reduces shipping date inquiries, increases customer satisfaction by showing estimated delivery date',
//     subDescription: 'Use code BFCM30 for exclusive 30% discount',
//     buttonText: 'Claim 30% OFF',
//     logo: images.estimated_delivery,
//     href: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_source=fbchat&utm_medium=homeinapp&utm_campaign=crs',
//     banner: images.banner_estimated_delivery,
//     star: '4.8',
//     plan: 'Free to install',
//     // marketingBanner: 'ads_order_tracking',
//     hasBFS: true,
//     ga4Event: Ga4Event.EstimatedClick,
//   },
//   {
//     title: 'Synctrack: Returns & Exchanges',
//     hasBFS: true,
//     brandURL: 'https://apps.shopify.com/partners/omegaapps',
//     description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue',
//     subDescription: 'Use code BFCM20 for exclusive 20% discount!',
//     buttonText: 'Claim 20% OFF',
//     logo: images.return_drive_app,
//     href: 'https://apps.shopify.com/omega-returns-drive?utm_source=fbchat&utm_medium=homeinapp&utm_campaign=crs',
//     banner: images.return_drive_banner_app,
//     star: '5.0',
//     plan: 'Free',
//     ga4Event: Ga4Event.ReturnClick,
//     // marketingBanner: 'ads_returnsdrive',
//   },
// ];
