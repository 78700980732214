import styled from 'styled-components';
interface Props {
  backgroundColor: string;
  textColor: string;
}

export const ContactFormStyled = styled.div<Props>`
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  .header {
    color: black;
    padding: 8px 8px 0 8px;
    .wrap-icon {
      display: flex;
      justify-content: end;
      cursor: pointer;
    }
    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
      }
    }
  }
  .body {
    height: 100%;
    max-height: 360px;
    overflow-y: auto;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .Polaris-TextField__Input {
      width: 100%;
    }
    .react-tel-input .form-control {
      width: 100%;
    }
    .react-tel-input {
      z-index: 21;
    }
  }
  .footer {
    padding: 0 0.5rem 0.5rem 0.5rem;
    .btn-submit {
      border: none;
      outline: none;
      width: 100%;
      padding: 6px 0;
      background-color: ${(props) => props.backgroundColor};
      color: ${(props) => props.textColor};
      border-radius: 8px;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;
