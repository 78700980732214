import BoldText from '@/components/BoldText';
import { ContactFormInputType, ICon } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice from '@/redux/slice/widget.slice';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import { MinusIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { ContactFormStyled } from './styled';
import PhoneInput from 'react-phone-input-2';
interface IProps {
  handleCloseWindow: () => void;
}
type InputType =
  | 'text'
  | 'email'
  | 'number'
  | 'integer'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'currency';
const ContactForm = ({ handleCloseWindow }: IProps) => {
  const dispatch = useDispatch();
  const contactForm = useChannelSelected(ICon.ContactForm).channel;
  const handleClose = () => {
    handleCloseWindow();
    dispatch(widgetSlice.actions.handleWindow(undefined));
  };

  return (
    <ContactFormStyled
      backgroundColor={contactForm?.advance?.formSubmitButtonBackgroundColor || '#000'}
      textColor={contactForm?.advance?.formSubmitButtonTextColor || '#fff'}
    >
      <div className="header">
        <div onClick={handleClose} className="wrap-icon">
          <Button variant="plain" icon={MinusIcon} />
        </div>
        <div className="header-title">
          <BoldText>{contactForm?.advance?.formTitle}</BoldText>
          <p>{contactForm?.advance?.formDescription}</p>
        </div>
      </div>
      <div className="body">
        {contactForm?.formFields?.map((item, index) => {
          let type: InputType;
          switch (item.typeInput) {
            case ContactFormInputType.Email:
              type = 'email';
              break;
            case ContactFormInputType.Date:
              type = 'date';
              break;
            case ContactFormInputType.Time:
              type = 'time';
              break;
            case ContactFormInputType.Phone:
              return (
                <>
                  <label>
                    {item.title}
                    {item.isRequired ? <span style={{ color: 'rgba(142, 31, 11, 1)' }}> *</span> : ''}
                  </label>
                  <PhoneInput country={'vn'} />
                </>
              );
            case ContactFormInputType.Number:
              type = 'number';
              break;
            default:
              type = 'text';
              break;
          }
          return (
            <TextField
              key={index}
              type={type}
              requiredIndicator={item.isRequired}
              label={item.title}
              placeholder={item.placeholder}
              autoComplete="off"
            />
          );
        })}
        {contactForm?.advance?.formAgreeEmailSubscription ? (
          <Checkbox label={contactForm?.advance?.formAgreeEmailContent} />
        ) : null}
        {contactForm?.advance?.formAgreeSMSSubscription ? <Checkbox label={contactForm?.advance?.formAgreeSMSContent} /> : null}
      </div>
      <div className="footer">
        <button className="btn-submit">{contactForm?.advance?.formSubmitButtonText}</button>
      </div>
    </ContactFormStyled>
  );
};

export default memo(ContactForm);
