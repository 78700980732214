import { config } from '@/config';
import { KeyOfAdvance, KeyOfFormFields } from '@/constants';
import { ContactFormInputType, ICon, PricingPlan } from '@/constants/enum';
import { capitalizeFirstLetter } from '@/helpers/string';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import widgetSlice, { channelsSelectedSelector } from '@/redux/slice/widget.slice';
import { Button, Checkbox, Divider, Select, Text, TextField } from '@shopify/polaris';
import { CheckIcon, DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ChannelsButton from '.';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import ColorPicker from '../ColorPicker';
import Switch from '../Switch';

interface IStatePreAction {
  isActive: boolean;
  isRequired: boolean;
  title: string;
  placeholder: string;
  onChange: (key: KeyOfFormFields) => (value: string | boolean) => void;
  handleDeleteField: (id: number) => void;
  id: number;
  typeInput: ContactFormInputType;
}
interface IProps {
  keyValue: ICon;
}
const PreExtendActionLayout = ({ ...props }: IStatePreAction) => {
  const [isEditName, setIsEditName] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 395 });
  const typeInputOptions = [
    {
      label: capitalizeFirstLetter(ContactFormInputType.Text),
      value: ContactFormInputType.Text,
    },
    {
      label: capitalizeFirstLetter(ContactFormInputType.Number),
      value: ContactFormInputType.Number,
    },
    {
      label: capitalizeFirstLetter(ContactFormInputType.Email),
      value: ContactFormInputType.Email,
    },
    {
      label: capitalizeFirstLetter(ContactFormInputType.Date),
      value: ContactFormInputType.Date,
    },
    {
      label: capitalizeFirstLetter(ContactFormInputType.Time),
      value: ContactFormInputType.Time,
    },
    {
      label: capitalizeFirstLetter(ContactFormInputType.Phone),
      value: ContactFormInputType.Phone,
    },
  ];
  return (
    <div className="mt-8">
      <div className="d-flex flex-center">
        <div className="mb-8" style={{ alignSelf: 'self-end' }}>
          <Switch isActive={props.isActive} onSwitch={props.onChange('isActive')} />
        </div>
        <div className="ml-8 w-full">
          <TextField
            value={props.title}
            onChange={props.onChange('title')}
            autoComplete="off"
            label="Title"
            disabled={!isEditName}
          />
        </div>
        <div className="d-flex mb-8" style={{ alignSelf: 'self-end' }}>
          <Button onClick={() => setIsEditName(!isEditName)} variant="plain" icon={!isEditName ? EditIcon : CheckIcon} />
          <Button onClick={() => props.handleDeleteField(props.id)} variant="plain" icon={DeleteIcon} />
        </div>
      </div>
      <div className={`mt-8 d-flex flex-center field-w-100 ${isSmallScreen ? 'f-wrap' : ''}`}>
        <div className="w-full">
          <Select
            options={typeInputOptions}
            label="Input type"
            value={props.typeInput}
            onChange={props.onChange('typeInput')}
            placeholder="Select data type"
          />
        </div>
        <div className="ml-8 mb-4" style={{ alignSelf: 'self-end' }}>
          <Checkbox label="Required" checked={props.isRequired} onChange={(checked) => props.onChange('isRequired')(checked)} />
        </div>
      </div>
      <div className="mt-8">
        <TextField
          placeholder={'Enter placeholder'}
          label="Placeholder"
          value={props.placeholder}
          onChange={props.onChange('placeholder')}
          autoComplete="off"
        />
      </div>
      <div className="mt-16">
        <Divider borderColor="border-inverse" />
      </div>
    </div>
  );
};

const ContactForm = ({ keyValue }: IProps) => {
  const dispatch = useDispatch();
  const channels = useSelector(channelsSelectedSelector);
  const channel = useChannelSelected(keyValue).channel;
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });

  const isLimited = useMemo(() => {
    return dataSetting?.data?.plan === PricingPlan.Free || dataSetting?.data?.plan === PricingPlan.Basic;
  }, [dataSetting?.data?.plan]);

  const handleChangeFormFields = (index: number) => (key: KeyOfFormFields) => (value: boolean | string) => {
    if (channel?.formFields) {
      const newFormFields = [...channel.formFields];
      newFormFields[index] = {
        ...newFormFields[index],
        [key]: value,
      };
      const newData = {
        ...channel,
        formFields: newFormFields,
      };
      const indexChannel = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[indexChannel] = newData;
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };
  const handleChangeFormAdvance = (key: KeyOfAdvance) => (value: string | boolean | number) => {
    if (channel?.advance) {
      const newData = {
        ...channel,
        advance: {
          ...channel.advance,
          [key]: value,
        },
      };
      const indexChannel = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[indexChannel] = newData;
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };

  const handleAddField = useCallback(() => {
    if (channel?.formFields) {
      let maxIndex = 0;
      channel.formFields.forEach((fieldData) => {
        if (fieldData.id) {
          if (fieldData.id > maxIndex) maxIndex = fieldData.id;
        } else {
          if (fieldData.customId && fieldData.customId > maxIndex) maxIndex = fieldData.customId;
        }
      });
      const newFormFields = [...channel.formFields];
      newFormFields.push({
        isActive: true,
        isRequired: true,
        title: 'Custom name',
        placeholder: 'Enter placeholder',
        customId: maxIndex + 1,
        typeInput: ContactFormInputType.Text,
      });
      const newData = {
        ...channel,
        formFields: newFormFields,
      };
      const indexChannel = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[indexChannel] = newData;
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  }, [channel, channels, dispatch, keyValue]);

  const isShowEmailSubscription = useMemo(() => {
    return channel?.formFields?.some((field) => field.typeInput === ContactFormInputType.Email);
  }, [channel?.formFields]);

  const isShowSMSSubscription = useMemo(() => {
    return channel?.formFields?.some((field) => field.typeInput === ContactFormInputType.Phone);
  }, [channel?.formFields]);

  const handleDeleteField = useCallback(
    (id: number) => {
      if (channel?.formFields) {
        const newFormFields = channel.formFields.filter((fieldData) => {
          if (fieldData.id) return fieldData.id !== id;
          else return fieldData.customId !== id;
        });
        const newData = {
          ...channel,
          formFields: newFormFields,
        };
        const indexChannel = channels.findIndex((item) => item.channel === keyValue);
        const newChannels = [...channels];
        newChannels[indexChannel] = newData;
        dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
      }
    },
    [channel, channels, dispatch, keyValue],
  );

  return channel ? (
    <ChannelsButton
      key={`${keyValue} channels button`}
      keyValue={keyValue}
      placeholder="Example: john@example.com"
      tooltip="Add your email address. E.g., support@premio.io"
      label="Your email to receive form answer from customers (optional)"
      isReverseContentToBottom
      disabledInput={isLimited}
      isShowLimitedText={isLimited}
      limitedTitle="Upgrade to Pro or Premium plan"
      limitedContent="to receive responses from contact form directly in your email."
      expandActionSettings={
        <div>
          <TextField
            placeholder="Form title"
            label="Contact form title"
            autoComplete="off"
            value={channel.advance?.formTitle}
            onChange={handleChangeFormAdvance('formTitle')}
          />
          <div className="mt-8">
            <TextField
              placeholder="Contact Form Content"
              label="Contact Form Content"
              autoComplete="off"
              value={channel.advance?.formDescription}
              onChange={handleChangeFormAdvance('formDescription')}
            />
          </div>
          <div className="mt-8 d-flex">
            <ColorPicker
              title="Button text Color"
              id={`text-color-${channel.channel}`}
              defaultValue={channel.advance?.formSubmitButtonTextColor || ''}
              onBlur={handleChangeFormAdvance('formSubmitButtonTextColor')}
            />
            <div className="ml-16">
              <ColorPicker
                title="Button background Color"
                id={`background-color-${channel.channel}`}
                defaultValue={channel.advance?.formSubmitButtonBackgroundColor || ''}
                onBlur={handleChangeFormAdvance('formSubmitButtonBackgroundColor')}
              />
            </div>
          </div>
          <div className="mt-8">
            <TextField
              placeholder="Button text"
              label="Button text"
              autoComplete="off"
              value={channel.advance?.formSubmitButtonText}
              onChange={handleChangeFormAdvance('formSubmitButtonText')}
            />
          </div>
          <div className="mt-8">
            <TextField
              placeholder="Thank you message"
              label="Thank you message"
              autoComplete="off"
              value={channel.advance?.formSubmitedMessage}
              onChange={handleChangeFormAdvance('formSubmitedMessage')}
            />
          </div>
          <div className="mt-8">
            <div className="d-flex mb-8">
              <Switch
                isActive={!!channel.advance?.isFormSubmitedRedirect}
                onSwitch={handleChangeFormAdvance('isFormSubmitedRedirect')}
              />
              <div className="ml-8">
                <Text as="p">Redirect visitors</Text>
              </div>
            </div>
            <BaseCollapsible id="redirect-collapsible" open={!!channel.advance?.isFormSubmitedRedirect}>
              <TextField
                placeholder={config.shop || ''}
                label=""
                labelHidden
                autoComplete="off"
                value={channel.advance?.formSubmitedRedirectUrl}
                onChange={handleChangeFormAdvance('formSubmitedRedirectUrl')}
              />
            </BaseCollapsible>
          </div>
          <div className="mt-8">
            <div className="d-flex mb-8">
              <Switch
                isActive={!!channel.advance?.isFormSubmitedAutoClose}
                onSwitch={handleChangeFormAdvance('isFormSubmitedAutoClose')}
              />
              <div className="ml-8">
                <Text as="p">Close form automatically</Text>
              </div>
            </div>
            <BaseCollapsible id="redirect-collapsible" open={!!channel.advance?.isFormSubmitedAutoClose}>
              <div className="d-flex flex-center">
                <label>Close after</label>
                <div className="text-filed" style={{ width: 80, margin: '0 8px' }}>
                  <TextField
                    type="number"
                    label=""
                    value={channel.advance?.formSubmitedCloseAfter + ''}
                    onChange={(value) =>
                      handleChangeFormAdvance('formSubmitedCloseAfter')(Math.max(Math.min(Number(value), 100), 0))
                    }
                    autoComplete="off"
                  />
                </div>
                <label>seconds</label>
              </div>
            </BaseCollapsible>
          </div>
          <div className="mt-8">
            <div className="d-flex mb-8">
              <Switch
                isActive={!!channel.advance?.formRequiredSubmitBeforeChat}
                onSwitch={handleChangeFormAdvance('formRequiredSubmitBeforeChat')}
              />
              <div className="ml-8">
                <Text as="p">Require Contact Form before chat session</Text>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="d-flex mb-8">
              <Switch
                // isLoading={!dataSetting?.data.enabledWriteCustomer}
                isActive={!!channel.advance?.formAgreeShopifySync}
                onSwitch={handleChangeFormAdvance('formAgreeShopifySync')}
              />
              <div className="ml-8">
                <Text as="p">Data Sync with Shopify</Text>
              </div>
            </div>
          </div>
        </div>
      }
      preExpandActionSettings={
        <div>
          {channel.formFields?.map((item, index) => {
            return (
              <PreExtendActionLayout
                handleDeleteField={handleDeleteField}
                key={`formfields-${item?.id ?? item?.customId ?? index}`}
                id={item.id ?? item.customId ?? 0}
                {...item}
                onChange={handleChangeFormFields(index)}
              />
            );
          })}
          {channel?.formFields ? (
            <div className="mt-8">
              <Button disabled={channel?.formFields?.length >= 10} onClick={handleAddField}>
                Add field
              </Button>
            </div>
          ) : null}
          {isShowEmailSubscription ? (
            <div className="mt-14">
              <Checkbox
                label="Email Subscription Checkbox"
                checked={channel?.advance?.formAgreeEmailSubscription}
                onChange={(value) => handleChangeFormAdvance('formAgreeEmailSubscription')(value)}
              />
              <TextField
                label="Content"
                autoComplete="off"
                disabled={!channel?.advance?.formAgreeEmailSubscription}
                value={channel?.advance?.formAgreeEmailContent}
                onChange={handleChangeFormAdvance('formAgreeEmailContent')}
              />
            </div>
          ) : null}
          {isShowSMSSubscription ? (
            <div className="mt-14">
              <Checkbox
                label="SMS Subscription Checkbox"
                checked={channel?.advance?.formAgreeSMSSubscription}
                onChange={(value) => handleChangeFormAdvance('formAgreeSMSSubscription')(value)}
              />
              <TextField
                label="Content"
                autoComplete="off"
                disabled={!channel?.advance?.formAgreeSMSSubscription}
                value={channel?.advance?.formAgreeSMSContent}
                onChange={handleChangeFormAdvance('formAgreeSMSContent')}
              />
            </div>
          ) : null}
        </div>
      }
    />
  ) : null;
};
export default memo(ContactForm);
