import { KeyOfMessageAdvance } from '@/constants';
import { ICon } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice, { channelsSelectedSelector } from '@/redux/slice/widget.slice';
import { IChanelProps } from '@/types/components/channels';
import { IUpload } from '@/types/components/upload';
import { TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChannelsButton from '.';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import { MessageVariant } from '../Icons/Variant/Message';
import RegularText from '../RegularText';
import Switch from '../Switch';
import UploadImage from '../UploadImage';

const Messenger = ({ keyValue }: IChanelProps) => {
  const dispatch = useDispatch();
  const channel = useChannelSelected(keyValue).channel;
  const messageProfileImage = channel?.advance?.messengerProfileImage;
  const channels = useSelector(channelsSelectedSelector);

  const handleChangeAdvance = (key: KeyOfMessageAdvance) => (value: string | boolean | IUpload) => {
    if (channel) {
      const index = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[index] = {
        ...channel,
        advance: {
          ...channel.advance,
          [key]: value,
        },
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };

  const handleUploadFile = (value: IUpload) => {
    const newChannels = [...channels];
    const index = newChannels.findIndex((item) => item.channel === keyValue);
    if (index !== -1) {
      newChannels[index] = {
        ...newChannels[index],
        advance: {
          ...newChannels[index].advance,
          messengerProfileImage: value,
        },
      };
    }
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };
  return channel ? (
    <ChannelsButton
      showAddAgent
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https://www.facebook.com/{your-page}"
      tooltip="Your Facebook page has to be available to all ages and countries (check your page's settings)."
      variants={MessageVariant({})}
      expandActionSettings={
        <div>
          <div className="d-flex">
            <Switch
              isActive={!!channel.advance?.messengerChatWidget}
              onSwitch={(value) => {
                handleChangeAdvance('messengerChatWidget')(value);
                dispatch(widgetSlice.actions.handleWindow(value ? ICon.Messenger : undefined));
              }}
            />
            <div className="ml-8">
              <RegularText>Display welcome message box</RegularText>
            </div>
          </div>
          <BaseCollapsible id="message-chat-widget" open={!!channel.advance?.messengerChatWidget}>
            <div className="mt-8">
              <TextField
                label="Title"
                autoComplete="off"
                value={channel.advance?.messengerTitle}
                onChange={handleChangeAdvance('messengerTitle')}
              />
            </div>
            <div className="mt-8">
              <TextField
                label="Message"
                autoComplete="off"
                value={channel.advance?.messengerWelcomeMessage}
                onChange={handleChangeAdvance('messengerWelcomeMessage')}
              />
            </div>
            <div className="mt-8">
              <TextField
                label="Placeholder input"
                autoComplete="off"
                value={channel.advance?.messengerPlaceholderText}
                onChange={handleChangeAdvance('messengerPlaceholderText')}
              />
            </div>

            <div className="mt-8">
              <UploadImage label="Add a profile image" url={messageProfileImage?.url} setFile={handleUploadFile} />
            </div>
          </BaseCollapsible>
        </div>
      }
    />
  ) : null;
};
export default memo(Messenger);
