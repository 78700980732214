import { ICon } from '@/constants/enum';
import { channelsSelectedSelector } from '@/redux/slice/widget.slice';
import { useSelector } from 'react-redux';

export const useChannelSelected = (channelKey: ICon) => {
  const isCustomChannel = channelKey.includes(ICon.CustomLink);
  const channel = useSelector(channelsSelectedSelector).find((item) => item.channel === channelKey);
  const customChannel = useSelector(channelsSelectedSelector).find((item) => {
    if (isCustomChannel) {
      return item.customLinkId === Number(channelKey.split(' ')[1]);
    }
    return undefined;
  });

  return {
    customChannel,
    channel,
  };
};
