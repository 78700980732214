import { AnimationScrollToTop } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { Select } from '@shopify/polaris';
import { memo } from 'react';
import ChannelsButton from '.';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import RegularText from '../RegularText';
import Switch from '../Switch';
import { ScrollTopVariant } from '../Icons/Variant/ScrollTop';
import { useDispatch, useSelector } from 'react-redux';
import widgetSlice, { channelsSelectedSelector } from '@/redux/slice/widget.slice';
const ScrollToTop = ({ keyValue }: IChanelProps) => {
  const dispatch = useDispatch();
  const channel = useChannelSelected(keyValue).channel;
  const channels = useSelector(channelsSelectedSelector);

  const handleChangeScrollAnimation = (value: AnimationScrollToTop | null) => {
    if (channel) {
      const index = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[index] = {
        ...channel,
        advance: {
          ...channel.advance,
          animationScrollToTop: value,
        },
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };

  const options = [
    { value: AnimationScrollToTop.Auto, label: 'Auto' },
    { value: AnimationScrollToTop.Smooth, label: 'Smooth' },
  ];

  return channel ? (
    <ChannelsButton
      variants={ScrollTopVariant({})}
      key={keyValue}
      keyValue={keyValue}
      placeholder=""
      tooltip={`Add button to fast go to top of page`}
      disabledInput
      expandActionSettings={
        <div>
          <div className="d-flex">
            <Switch
              isActive={!!channel.advance?.animationScrollToTop}
              onSwitch={(value) => {
                handleChangeScrollAnimation(value ? AnimationScrollToTop.Smooth : null);
              }}
            />
            <div className="ml-8">
              <RegularText>Open advance settings</RegularText>
            </div>
          </div>
          <BaseCollapsible id="go-to-top-widget" open={!!channel.advance?.animationScrollToTop}>
            <div className="mt-8">
              <Select
                label="Scroll animation"
                placeholder="Select scroll animation"
                options={options}
                onChange={(value: AnimationScrollToTop) => handleChangeScrollAnimation(value)}
                value={channel.advance?.animationScrollToTop || AnimationScrollToTop.Auto}
              />
            </div>
          </BaseCollapsible>
        </div>
      }
    />
  ) : null;
};
export default memo(ScrollToTop);
