import { DynamicContentBannerStyled } from '@/components/DynamicContentBanner/styled';
import { Ga4Event } from '@/constants/enum';
import { useInView } from '@/hooks/useInView';
import { apiCaller } from '@/redux/query';
import {
  isShowedDynamicContentBannerSelector,
  isShowedFbPixelSelector,
  isShowedOderTrackingSelector,
  isShowedSyncTrackSelector,
  isSubmittedFbPixelSelector,
  isSubmittedOderTrackingSelector,
  isSubmittedSyncTrackSelector,
  sessionSlice,
} from '@/redux/slice/session.slice';
import { Banner, Button } from '@shopify/polaris';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IDataBanner {
  id: Ga4Event;
  title: string;
  description: React.ReactNode;
  url: string;
}

const mockData: IDataBanner[] = [
  {
    id: Ga4Event.SynctrackImpression,
    title: (
      <>
        <b>Sync tracking info to PayPal/Stripe for faster funds release and avoid disputes.</b>
        <br />
        <b>Get funds in 3 days</b>
      </>
    ) as any,
    description: (
      <p className="mb-8">
        Synctrack PayPal Tracking Sync is an official PayPal partner. Synctrack keeps your business transparent, saves time &
        avoids PayPal holds.
      </p>
    ),
    url: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
  },
  {
    id: Ga4Event.OderTrackingImpression,
    title: 'Reduce shipping support tickets, boost sales & customer trust.',
    description: (
      <p className="mb-8">
        Synctrack Order Tracking track your orders in real-time and send shipping notifications to keep customers informed.
      </p>
    ),
    url: 'https://apps.shopify.com/omega-order-tracking?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
  },
  {
    id: Ga4Event.FbPixelImpression,
    title: 'Run Facebook ads but get no sales',
    description: (
      <p className="mb-8">
        Lost sales = bad ads tracking. Achieve expert-level tracking with Facebook Pixel and Conversion API to capture every
        signal, understand buyer behavior, and retarget ads that sell better!
      </p>
    ),
    url: 'https://apps.shopify.com/facebook-multi-pixels?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
  },
];

const randomIndex = () => Math.floor(Math.random() * mockData.length);

const DynamicContentBanner = () => {
  const dispatch = useDispatch();
  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  const isShowedDynamicContentBanner = useSelector(isShowedDynamicContentBannerSelector);
  const [data, setData] = useState<IDataBanner>(mockData[randomIndex()]);
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);
  const isSubmittedSyncTrack = useSelector(isSubmittedSyncTrackSelector);
  const isSubmittedOderTracking = useSelector(isSubmittedOderTrackingSelector);
  const isShowedSyncTrack = useSelector(isShowedSyncTrackSelector);
  const isShowedOderTracking = useSelector(isShowedOderTrackingSelector);
  const isSubmittedFbPixel = useSelector(isSubmittedFbPixelSelector);
  const isShowedFbPixel = useSelector(isShowedFbPixelSelector);
  useEffect(() => {
    const id = setInterval(() => {
      setData((prevData) => {
        const currentIndex = mockData.findIndex((item) => item.title === prevData.title);
        const nextIndex = (currentIndex + 1) % mockData.length;
        return mockData[nextIndex];
      });
    }, 7000);

    return () => clearInterval(id);
  }, []);

  const handleSendGA4Event = useCallback(
    (event: Ga4Event) => {
      const dataSubmit = {
        eventName: event,
        params: {},
      };
      if (event === Ga4Event.SynctrackClick) {
        if (!isSubmittedSyncTrack) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedSyncTrack', value: true }));
        }
      } else if (event === Ga4Event.OderTrackingClick) {
        if (!isSubmittedOderTracking) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedOderTracking', value: true }));
        }
      } else if (event === Ga4Event.FbPixelClick) {
        if (!isSubmittedFbPixel) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedFbPixel', value: true }));
        }
      } else {
        sendGA4Event(dataSubmit);
      }
    },
    [isSubmittedSyncTrack, sendGA4Event, dispatch, isSubmittedOderTracking, isSubmittedFbPixel],
  );

  useEffect(() => {
    if (isInView) {
      const current = mockData[randomIndex()];
      if (!isShowedSyncTrack && current.id === Ga4Event.SynctrackImpression) {
        dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedSyncTrack', value: true }));
        handleSendGA4Event(Ga4Event.SynctrackImpression);
      }
      if (!isShowedOderTracking && current.id === Ga4Event.OderTrackingImpression) {
        dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedOderTracking', value: true }));
        handleSendGA4Event(Ga4Event.OderTrackingImpression);
      }
      if (!isShowedFbPixel && current.id === Ga4Event.FbPixelImpression) {
        dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedFbPixel', value: true }));
        handleSendGA4Event(Ga4Event.FbPixelImpression);
      }
    }
  }, [dispatch, handleSendGA4Event, isInView, isShowedOderTracking, isShowedSyncTrack, isShowedFbPixel]);

  const handleHideBanner = () => {
    dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedDynamicContentBanner', value: false }));
  };

  return isShowedDynamicContentBanner ? (
    <DynamicContentBannerStyled ref={ref}>
      <Banner title={data.title} onDismiss={handleHideBanner}>
        <div>
          {data.description}
          <Button
            url={data.url}
            target="_blank"
            variant="primary"
            onClick={() =>
              handleSendGA4Event(data.id === Ga4Event.SynctrackImpression ? Ga4Event.SynctrackClick : Ga4Event.OderTrackingClick)
            }
          >
            Try for free
          </Button>
        </div>
      </Banner>
    </DynamicContentBannerStyled>
  ) : null;
};

export default DynamicContentBanner;
