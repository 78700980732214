import { KeyOfCartAdvance } from '@/constants';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { InlineGrid } from '@shopify/polaris';
import { memo } from 'react';
import ChannelsButton from '.';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import ColorPicker from '../ColorPicker';
import RegularText from '../RegularText';
import Switch from '../Switch';
import { CartVariant } from '../Icons/Variant/Cart';
import { useDispatch, useSelector } from 'react-redux';
import widgetSlice, { channelsSelectedSelector } from '@/redux/slice/widget.slice';
const GoToCard = ({ keyValue }: IChanelProps) => {
  const dispatch = useDispatch();
  const channel = useChannelSelected(keyValue).channel;
  const channels = useSelector(channelsSelectedSelector);
  const handleChangeAdvance = (key: KeyOfCartAdvance) => (value: string | boolean) => {
    if (channel) {
      const index = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[index] = {
        ...channel,
        advance: {
          ...channel.advance,
          [key]: value,
        },
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };

  return channel ? (
    <ChannelsButton
      variants={CartVariant({})}
      key={keyValue}
      keyValue={keyValue}
      placeholder=""
      tooltip={`Add button to go to card page`}
      disabledInput
      expandActionSettings={
        <div>
          <div className="d-flex">
            <Switch
              isActive={!!channel.advance?.isShowQuantityProductInCart}
              onSwitch={handleChangeAdvance('isShowQuantityProductInCart')}
            />
            <div className="ml-8">
              <RegularText>Display number of products in cart</RegularText>
            </div>
          </div>
          <BaseCollapsible id="go-cart-widget" open={!!channel.advance?.isShowQuantityProductInCart}>
            <div className="d-flex mt-8">
              <Switch
                isActive={!!channel.advance?.isHiddenButtonInCartPage}
                onSwitch={handleChangeAdvance('isHiddenButtonInCartPage')}
              />
              <div className="ml-8">
                <RegularText>Hidden this button in cart</RegularText>
              </div>
            </div>
            <div className="mt-8">
              <InlineGrid columns={2} gap={'200'}>
                <ColorPicker
                  iconKey={keyValue}
                  title="Total quantity background"
                  id={`total-quantity-background-color-${keyValue}`}
                  defaultValue={channel.advance?.totalQuantityBackgroundColorCartPage}
                  onBlur={handleChangeAdvance('totalQuantityBackgroundColorCartPage')}
                />
                <ColorPicker
                  title="Total quantity text"
                  id={`total-quantity-text-${keyValue}`}
                  defaultValue={channel.advance?.totalQuantityTextColorCartPage}
                  onBlur={handleChangeAdvance('totalQuantityTextColorCartPage')}
                />
              </InlineGrid>
            </div>
          </BaseCollapsible>
        </div>
      }
    />
  ) : null;
};
export default memo(GoToCard);
