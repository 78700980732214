import { LinkedinType } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { RadioButton } from '@shopify/polaris';
import { memo } from 'react';
import ChannelsButton from '.';
import RegularText from '../RegularText';
import { useDispatch, useSelector } from 'react-redux';
import widgetSlice, { channelsSelectedSelector } from '@/redux/slice/widget.slice';

const Linkedin = ({ keyValue }: IChanelProps) => {
  const dispatch = useDispatch();
  const channel = useChannelSelected(keyValue).channel;
  const channels = useSelector(channelsSelectedSelector);
  const handleChangeLinkedInType = (value: LinkedinType) => {
    if (channel) {
      const index = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[index] = {
        ...channel,
        advance: {
          ...channel.advance,
          linkedinType: value,
        },
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: Vk_username."
      tooltip={`"Username for the VK account (part of the web page address). For "vk.com/example " the username is "example"`}
      expandActionSettings={
        <div>
          <RegularText>LinkedIn</RegularText>
          <div className="d-flex">
            <RadioButton
              label="Personal"
              checked={channel.advance?.linkedinType === LinkedinType.Personal}
              id={LinkedinType.Personal}
              name={LinkedinType.Personal}
              onChange={(_, value: LinkedinType) => {
                handleChangeLinkedInType(value);
              }}
            />
            <div className="ml-8">
              <RadioButton
                label="Company"
                checked={channel.advance?.linkedinType === LinkedinType.Company}
                id={LinkedinType.Company}
                name={LinkedinType.Company}
                onChange={(_, value: LinkedinType) => {
                  handleChangeLinkedInType(value);
                }}
              />
            </div>
          </div>
        </div>
      }
    />
  ) : null;
};
export default memo(Linkedin);
